@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300..700&display=swap');

@media (max-width: 640px) {
    html {
        font-size: 14px;
    }
}

@media (min-width: 2048px) {
    html {
        font-size: 24px;
    }
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #0D0D0F;
    min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Jura", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
}

.font-jura {
    font-family: "Jura", sans-serif;
    font-optical-sizing: auto;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: rgba(black, 0.5);
    cursor: pointer;
    box-sizing: border-box;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 7px #27272A; 
    border-radius: 3px;
}
::-webkit-scrollbar-thumb {
    background: #71717A;
    border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #E4E4E7; 
}